.search-bar-container {
    width: 33%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    height: 2.4em;
    align-self: center;
    justify-self: center;
    margin-top: 1em;
    margin-bottom: 1em;
}

.search-bar {
    position: relative;
    height: 25px;
    min-width: 500px;
}

.search-bar > input {
    padding-left: 2%;
    width: 100%;
    height: 100%;
    margin-top: 0;
    border: 1px solid gray;
    border-radius: 45px;
    padding-left: 5%;

}

.search-bar > input:focus {
    outline: none;

}

.search-bar > input:active {
    border: 1px solid transparent
}

.search-results {

    background-color: white;
    border-radius: 15px;
    position: relative;
    right: 3%;
    top: 15%;
    width: 110%;
    -webkit-box-shadow: 0px 0px 49px -28px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 49px -28px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 49px -28px rgba(0,0,0,0.75);

}

.search-results > div {
    padding-top: 4%;
    padding-bottom: 2%;
    font-size: 0.8rem;
}



.search-results > div > a > img {
    height: 75px;
    width: 20%;
    object-fit: cover;
    border-radius: 10px;
    margin-left: 3%;
}

.search-results > div > a > div {
    width: 70%;
    margin-left: 4%;
    white-space: pre-wrap;
    white-space: pre-line;
    word-wrap: break-word;
    justify-content: left;
    /* text-align: left; */
    align-items: center;
    /* justify-items: left; */
    display: flex;
    padding: 5px;
    /* vertical-align: middle; */
    /* word-break: break-all; */
    /* display: -webkit-box; */
    /* -webkit-line-clamp: 2;
            line-clamp: 2; */
    /* -webkit-box-orient: horizontal; */
    text-overflow: ellipsis;
}

.search-results > div > a > div:hover {

    background-color: lightgray;
    border-radius: 10px;
}

.search-results > div > a > img:hover {
    opacity: .5;
    /* background-color: lightgray; */
    /* border-radius: 10px; */
}

.search-results > div > a  {
   text-decoration: none;
   color: black;
}

.search-results > div > a {
    display: flex;
    flex-direction: row;
}
