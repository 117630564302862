#spotImage {
    height: 250px;
    width: 250px;
    border-radius: 20px;
    min-width: 250px;
    min-height: 250px;
}

#city-country-stars {
    font-weight: bolder;
    display: flex;
    /* text-align: justify; */
    justify-content: space-between;
}

#price {
    font-weight: bold;
}

/* span#avgRating {
    font-weight: normal;
    margin: auto;
    text-align: right;
} */
span#night {
    font-weight: normal;

}

#giant-box {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    top: 100px;
    /* grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1fr); */
    gap: 1.75rem;
    width: min(90%);
    margin-inline: auto;
}

div.allSpots-name {
    color: grey;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: .90em;
}
