 #logo {
     height: 100%;
     width: 100%;


 }

 /* button#user-circle {
     background-color: white;
     top: 0px;
     position: relative;
     display: flex;
     width: 86px;

 } */



 button.login-logout-buttons {
     position: relative;
     top: 30px;

 }

 /* #createASpot {
     display: grid;
     position: relative;


 } */

 .modalNavBar {

     position: fixed;
     top: 0;
     background-color: white;
     border-bottom: 1px rgb(223, 219, 219) solid;
     display: flex;
     justify-content: space-between;
     align-items: center;
     height: 75px;
     padding: 0 2rem 0 5rem;
     width: 100%;
     z-index: 1;
     box-sizing: border-box;
     margin: 0px;
 }

 .spotDetails {
     display: flex;
     position: absolute;
     top: 50px;
     width: 100%;
     align-items: left;
     margin: 20%;
     justify-content: center;
     flex-direction: column;
 }

 /* div#createASpot {

     position: fixed;
     display: flex;
     justify-content: space-between;
     align-items: right;
     right: 200px;
     z-index: 1;


 } */

 .login-logout-buttons {
     position: relative;
     display: flex;
     justify-content: center;
     align-items: center;
     text-align: center;
     border-radius: 5px;
     border: 0px;
     padding: 10px;
     background-color: rgb(255, 255, 255);
     width: 65%;
     color:rgba(0, 0, 0, 0.5);
     /* color: white; */
     border: grey 1px solid;
 }

 button {
     position: relative;
     display: flex;
     justify-content: center;
     align-items: center;
     text-align: center;
     border-radius: 5px;
     border: 0px;
     padding: 10px;
     background-color: rgb(238, 17, 65);
     color: white;
     width: 100%;
     cursor: pointer;
 }



 /* .profile-dropdown {
     top: 500px;
 } */

 /* div.become-a-host {

     height: 130px;
     width: 130px;
     border-radius: 10px;
     justify-content: center;
     align-items: center;
     justify-items: center;
     vertical-align: middle;
     gap: 5px;

 } */



 div.dropdown{
    position: relative;
    display: flex;
    top: -20px;
 }



 .fas.fa-bars {
    line-height: 16px;
    padding-left: 5px;
    padding-right: 15px;
    font-size: 14px;
    color: grey;
 }

 .fas.fa-user-circle {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.5);
 }
 button.user-circle {
    /* display: flex; */
    background-color: white;
    /* position: relative; */
    /* align-items: initial; */
    /* justify-content: right; */
    /* align-content: initial; */
    border-radius: 20px;
    border: 1px grey solid;
    width: 70px;
    position: absolute;
    top: 0px;
    display: flex;

}

button.user-circle:hover{
    background-image: linear-gradient(to right, #ffffff, #ffffff, #ffffff, #ffffff);
    box-shadow: 2px 3px 1px rgba(145, 145, 145, 0.4);
}


li.username-email{
    position: relative;
    top: 0px;


}

button.logout{
    position: relative;
    top: 10px;
    margin-bottom: 5px;
    width: 100%;
    height: 50%;
    /* background-color: white; */
    /* color: black; */
    /* height: auto; */
    /* border: 1px grey solid; */

}

ul.profile-dropdown{
    position: relative;
    text-align: center;
    right: 65px;
    border: 1px grey solid;
    border-radius: 20px;
    /* padding: 10px; */
    height: fit-content;
    padding: 20px;
    z-index: 1;
    box-shadow: 3px 3px 3px 3px rgba(145, 145, 145, 0.4);
    background-color: white;
    top: 30px;
}

/* div.become-a-host{
    right: 200px;
    position: relative;
}
 */

div.hostAndprofile{
    /* right: 200px; */
    position: relative;
    /* display: block; */
    width: 200px;
    height: 200px;
    top: 100px
}


div#createASpot{
    right: 100px;
    position: relative;
}
a#host-text:hover {
    background-color: rgba(128, 128, 128, 0.247);
    border-radius: 30px;

 }

 a#host-text {
     /* margin-top: auto;
     margin-bottom: auto;
     margin-right: auto;
     margin-left: auto; */
     text-decoration: none;
     /* text-align: center; */
     /* vertical-align: center; */
     /* vertical-align: middle; */
     /* position: relative; */
     font-size: .9rem;
     color: black;
     font-weight: bold;
     /* margin-bottom: 10px; */
     /* height: auto; */
     /* box-sizing: border-box; */
     position: fixed;
     right: 190px;
     top: 30px;
     /* right: 200px; */
     /* top: 100px; */


 }

 /* div.become-a-host{
    right: 200px;
    position: relative;
 } */


 /* div.main-login-dropdown-div {
    border: 1px grey solid;
 } */
