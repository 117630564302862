/* TODO Add site wide styles */
html {
    font-family: 'Inter', sans-serif;
}

button {
    color: white;

}

button:hover{
    background-image: linear-gradient(to right, #eb3941, #f15e64, #e14e53, #e2373f);
    box-shadow: 0 5px 1px rgba(242, 97, 103, .4);
}
input {
    height: 30px;
    border-radius: 5px;
    border: grey 1px solid;
}


a {
    text-decoration: none;
}
