/* frontend/src/context/Modal.css */
#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  height: auto;
  width: auto;
}

#modal-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-radius: 20px;
  width: 500px;
  height: auto;

}

label {
  /* padding: 10px; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 5px; */
  /* width: 50%; */
  /* height: 50%; */
  /* border-radius: 5px; */

}

h2 {
  text-align: center;
}
/*
.input-fields {
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  height: 30%;
  border-radius: 30px;
  border-color: gray;

} */

button#loginButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  border: 0px;
  padding: 10px;
  background-color: rgb(238, 17, 65);
  width: 100%;
  margin: 1px;

}

input.login{
  width: 100%;

}



button#submit-button {
  position: relative;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  border: 0px;
  padding: 10px;
  margin: 1px;
  background-color: rgb(238, 17, 65);
  width: 100%;
  height: 100%;
}

/* #input-box {
  border-radius: 5px;
  border: 1px solid grey;
} */

/* button#demo-user-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  border: 0px;
  margin: 10px;
  padding: 10px;
  background-color: rgb(238, 17, 65);
  width: 100%;
} */
