img#spotDetailImage {
    width: 950px;
    height: 500px;
    display: flex;
    border-radius: 15px;
    padding-bottom: 50px;
}

div.spotDetails {
    text-align: left;
    margin: 0 auto;
    padding-bottom: 100px;
    justify-content: center;
    align-content: left;
    align-items: center;
    justify-items: center;
    position: relative;
    top: 100px;



}

div {
    justify-content: center;
}

/* div.modalNavBar {
    position: relative;
} */

button.spot-delete-button {
    width: 200px;
    border-radius: 5px;
    border: 0px;
    padding: 10px;
    background-color: rgb(238, 17, 65);
    margin: 5px;
}

button.spot-edit-button {
    width: 200px;
    margin: 5px;
    border-radius: 5px;
    border: 0px;
    padding: 10px;
    background-color: rgb(238, 17, 65);
    text-decoration: none;
}

button.spot-edit-button:visited{
    text-decoration: none;
}


div.spot-details-buttons {
    display: flex;
    position: relative;
    justify-content: left;
    text-decoration: none;


}

div.spot-image {
    min-width: 720px;
    min-height: 200px;
}


ul {
    list-style: none;
    margin: 0;
    padding: 0;
    /* font-family: 'Big Shoulders Display', cursive;
    font-family: 'Fraunces', serif;
    font-family: 'Lexend Deca', sans-serif;
    font-family: 'Montserrat', sans-serif; */
}



div.main-review-div {
    justify-content: right;
    width: 100%;
}

h1.spot-name-h1 {
    text-align: left;
    width: 950px;
}

p.stars-number {
    width: 950px;
}

h2.host-name {
    text-align: left;
    /* width: 950px; */
}

div.address-review-div {
    display: flex;
    flex-direction: row;
    gap: 40px;
    /* width: 950px; */
}

div.spotAddress {
    /* width: 500px; */
    margin-top: 15px;
    font-size: 1.1rem;
    line-height: 1.75rem;
}

div.spot-reviews {
    width: 400px;
}

div.hosted-and-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* width: 950px; */
    margin-bottom: 10px;
}

div.extra-details-with-emojis {
    display: flex;
    flex-direction: column;
    border-top: 1px grey solid;
    border-bottom: 1px grey solid;
}

div.aircover {
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px grey solid;
}

p.emojitext {
    color: grey;
    font-size: .9rem;

}
