/* div.spot-reviews {
   position: relative;
    width: 250px;
   height: 250px;
   /* position: fixed; */




/* form {
    position: relative;
    top: 400px;
} */

/* .review-buttons {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    border: 0px;
    padding: 10px;
    background-color: rgb(238, 17, 65);
    width: 100%;
}

div.review-box {
    position: relative;
    top: 550px;
    left: 200px;
    width: 720px;
}

input.review-inputs{
    position: relative;
    top: 700px;
    left: 120px;
    width: 350px;
}

button#create-review-button.review-buttons{
    position: relative;
    top: 550px;
    left: 200px;
    width: 360px;
}


p.list-of-reviews {
    position: relative;
    top: 325px;
    left: 700px;
    width: 360px;
}

} */

h3.name-of-spot{
    text-align: center;
    width: 100%;
}

h4.list-of-reviews {
    border-top: 1px lightgray solid;
    padding-top: 25px;
    text-align: left;
    font-size: 24px;
}

.spot-reviews-container {
    width: 950px;

}

div.spot-reviews{
    border: grey 0px solid;
    /* padding: 10px; */
    /* border-radius: 10px;
    box-shadow: 0px 0px 5px 2.5px rgba(128, 128, 128, 0.473); */
}

button.review-buttons {
    margin-top: 10px;
}

input.review-inputs {
    width: 300px;
}


h4.leave-review-h4{
    text-align: center;
}

ul.errorsList-reviews {
    margin: 20px;
    list-style-type: '· ';
}

input.review-inputs-write{
    height: 50px;

    width: 100%;
}

input.review-inputs-stars{
    width: 100%;
}

i#profile-review.fas.fa-user-circle{
    height: 20px;
    width: 25px;
}

li.li-li {
    font-size: 1rem;
    line-height: 1.5rem;
}

li.li-li-userInfo {
    margin-top: 30px;

}

span#first-last-name{
    font-size: 1.2rem;
    font-weight: bolder;
}
