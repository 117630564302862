div.hosted-by {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;


}

div.host-and-details {
    justify-content: flex-start;
    width: 90%;

}

.left-right-container {
    display: flex;
    flex-direction: row;
    width: 950px;
}

.details-left {
    width: 600px;
}

/* .create-booking-div {
    display: flex;
    height: auto;
} */
.booking-form-container {
    border: grey 0px solid;
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0px 0px 5px 2.5px rgba(128, 128, 128, 0.473);
    width: 300px;
    height: 40%;
    display: flex;
    flex-direction: column;
}

/* .booking-form-headers {
    border: 1px red solid;
} */

.header-first {
    /* border: 1px green solid; */
    display: flex;
    justify-content: space-between;
    padding: 15px;
}

div.header-right {
    display: flex;
    align-items: center;
    font-size: 14px;

}
span.avgRat {
    font-weight: bold;
}

.header-price {
    /* border: 1px yellow solid; */
    font-weight: bold;
    font-size: 22px;
}

span.night {
    color: grey;
    font-size: 18px;
    font-weight: lighter;
}

/* .header-rest {
    border: 1px purple solid;
}

.actual-form {
    border: 1px blue solid;
} */

.booking-form {
    /* border: 1px brown solid; */
    justify-content: center;
}

/* .booking-bot {
    border: 1px lightblue solid;
}


} */

.bot-content {
    /* border: 1px orange solid; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
}

.bot-bot {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
}
